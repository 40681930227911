@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

.recentlyAddedTitle {
  color: #131313;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.graphAbout {
  color: var(--text-100-dark, #05000f);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-group-text {
  background-color: white;
}

.search-input {
  border-left: 1px solid white !important;
  border-radius: 20px;
  border-color: #e7e6ea;
  box-shadow: 0 0 0px #719ece;
}
.search-input:focus {
  outline: none !important;
  border-color: #e7e6ea;
  box-shadow: 0 0 0px #719ece;
}
.input-group-text {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-color: #e7e6ea;
  box-shadow: 0 0 0px #719ece;
  /* border-radius: 40px !important; */
  /* border: 1px solid var(--text-20-stroke, #E7E6EA); */
}

.schoolList .active {
  border-radius: 36px;
  background: var(--Primary-60, #9566e8);
  color: #fff !important;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header {
  color: #000;
  font-family: Poppins;
  font-size: 18.526px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-align: center;
  padding: 16px;
}

.profilePlaceholder {
  background-image: url("../Images/ManagerDashboard/profile_placeholder_img.svg");
  background-repeat: no-repeat;
}

.main1 {
  border-radius: 16px;
  background: var(--Green-10, #f2f6ef);
  gap: 24px;
}

.main2 {
  border-radius: 16px;
  background: var(--Blue-10, #edf3fc);
}

.container-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.Numbers {
  color: #131313;
  text-align: right;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.right-text {
  color: var(--Error-60, #ef6868);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.left-text {
  color: #1f1d39;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.vertical-line {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  height: 50%;
}

.right-number {
  color: var(--Error-60, #ef6868);
  text-align: right;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.schoolAndTrainerCount {
  margin-top: 25px;
}

.schoolAndTrainerCount .card {
  border: none;
}

#workExpDropdown {
  width: 100%;
  padding: 7px;
  border-radius: 16px;
  border: 1px solid var(--text-20-stroke, #e7e6ea);
  /* color: var(--text-40-dark-grey, #9f99ab); */
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
}

.addTrainer input {
  border-radius: 16px;
  border: 1px solid var(--text-20-stroke, #e7e6ea);
  /* color: var(--text-40-dark-grey, #9f99ab); */
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
}
.deleteIcon,
.deleteIcon:hover {
  color: #da4505;
  border: 1px solid #da4505;
  border-radius: 20px;
}
.confirmDelete {
  border: 1px dashed #9566e8;
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;
}
.yes_btn,
.yes_btn:hover {
  color: #9566e8;
  border: 1px solid #9566e8;
  border-radius: 20px;
  width: 100%;
}
.no_btn,
.no_btn:hover {
  color: white;
  background-color: #9566e8;
  border: 1px solid #9566e8;
  border-radius: 20px;
  width: 100%;
}
.deleteTitle {
  font-size: 24px;
  font-weight: 500;
  color: #211c37;
}
.deleteMsg {
  font-size: 14px;
  font-weight: 400;
  color: #676767;
}
.recharts-surface {
  width: 80% !important;
}
.threeDots:after {
  content: "\2807";
  font-size: 24px;
}
.videoOptions {
  position: absolute;
  margin-left: -5rem;
  margin-top: -20px;
  cursor: pointer;
  /* z-index: 1; */
}
.threeDots {
  cursor: pointer;
}
