.Stu_Assess_header {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
}

.stu_que_text {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;

}


.stu_Asses_que_title {
    background: #FEDFD2;
    width: 38px;
    height: 38px;
    margin-top: 10px;
    border-radius: 30px;
}

.stu_Asses_que_title:hover {
    cursor: pointer;

}

.Stu_answer_header {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

}

.stu_assess_card {
    background: #F8F8F9;
    width: 100%;
    height: auto;
    margin-left: 12px;
    /* padding: 16px; */
    margin-right: 20px;
    border-radius: 24px;
    border: none;


}

.stu_assess_card:hover {
    cursor: pointer;
    background: #68953B;
    color: white;

}

.selected {
    background: #68953B;
    color: white;
}

.stu_assess_ans_text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 0px;

}

.stu_Asses_que_title_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    /* text-align: left; */
    margin-top: 6px;

}

.question_nav_div {
    /* background: #68953B; */
    background: #F8F8F9;
    width: 64px;
    height: 64px;
    padding: 10px 16px 10px 16px;
    border-radius: 36px;
}

.question_nav_div:hover {
    cursor: pointer;
    /* background: #68953B; ; */
    background: #9566E8;
}

.question_nav_number {
    margin-top: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    /* text-align: left; */
    /* color: #FFFFFF; */
    /* color: black; */

}

.que_nav_div {
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
}

.que_main_div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    /* height: 800px; */
    height: auto;
    /* border-left: 1px solid #E7E6EA; */
}



.ques_nav_link_main_div {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 650px;
    max-height: auto;
    min-height: 650px; 
    /* margin-bottom: 40vh; */
    border-left: 1px solid #E7E6EA;
}

.stu_assess_indication {

    width: 24px;
    height: 24px;
    /* padding: 10px 16px 10px 16px; */
    border-radius: 36px;
    margin-right: 10px;


}

.assess_indication_wrapper {
    padding: 4px 15px 4px 15px;
}

.answered {
    background: #68953B !important;
    color: white;
}

.not-answered {
    background: #FBD707;
    /* color: black; */

}

.yet-to-visit {
    background: #F8F8F9;

}

.answered-text {
    /* background: #68953B; */
    color: white;
}

.not-answered-text {
    /* background: #FBD707; */
    color: black;

}


.stu_assess_indi_text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

}

.assess_indication_main_div {
    /* width: 90%; */
    width: 100%;
    margin-left: inherit;
}

.current-question {
    background: #9566E8 !important;
    color: white;
}

.option-1 {
    background: #FEDFD2;
}

.option-2 {
    background: #C9DBF7;

}

.option-3 {
    background: #D9E4CE;

}

.option-4 {
    background: #FEF7CD;

}

.prev_button {
    /* background-color: #9566E8;
    color: white; */
    /* border-radius: 16px; */
    /* width: 335px; */
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    max-width: 335px;
    width: 80%;
    height: 48px;
    /* gap: 16px; */
    border-radius: 16px;
    color: #9566E8;
    border: 1px solid #9566E8;
    background-color: white;
}

.next_button {
    border-radius: 32px;
    /* width: 335px; */
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 80%;
    max-width: 335px;
    height: 48px;
    /* gap: 16px; */
    color: white;
    border: 1px solid #9566E8;
    background-color: #9566E8;
}

.next_button:hover {

    color: #9566E8;
    background-color: white;
    /* background-color:#9566E8 ; */
    /* color: black; */
    border: 1px solid #9566E8;
}

.nextQuestion_button {
    max-width: 335px;
    width: 80%;

    height: 48px;
    /* padding: 12px 111.5px 12px 111.5px;  */
    border-radius: 16px;
    background: #9566E8;
    color: white;
    border: none;
    margin-bottom: 48px;
    /* display: flex; */
    /* margin-left: 10px; */
    /* text-align: left; */
}

.timer_header {
    display: flex;
    justify-content: space-between;
}

.timer_div {
    background: #FFFBE3;
    font-family: 'poppins';
    color: #988202;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    height: 50px;
    padding: 11px 12px 11px 12px;
    gap: 6px;
    font-size: 16px;
    font-weight: 500;
}

.content {
    display: flex;
    justify-content: space-around;
}

.timer_image {
    margin-right: 6px;
}
.header_text {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #9F99AB;
  
  }
  .topic_div
  {
      font-family: "Poppins";
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: center;
      color: #05000F;
  }
  .first_info
  {
      /* width:624px; */
      /* height: 84px; */
      padding: 16px 20px 16px 20px;
      border-radius: 24px;
      gap: 16px;
      background-color: #F8F8F9;
  
  
  }
  .rule_div_1
  {
      margin-right:0px;
      margin-left: 0px;
      height: 100%;
      border-radius: 24px;
      background: #F8F8F9;
      margin-bottom: 8px;
      
  }
  
  .top_module
  {
       /* width: 624px; */
      /* height: 81px; */ 
      padding: 7px;
      margin-top: 36px;
      margin-bottom: 16px;
     
  }
  .icon_div_1
  {
      /* margin-top:4px; */
      display:flex;
      justify-content: center;
  }
  /* .assessment_text
  {
      margin-left: 4px;
  } */
  .assessment_text_header
  {
      /* width: 211px;
      height:49px; */
     
      /* gap: 4px; */
      margin-top: 10px;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
  
  }
  .assessment_icon 
  {
      height: 100%;
  }
  .assessment_text
  {
      height: 100%;
  }
  .assessment_lock_icon
  {
      height: 100%;
  }
  
  .assessment_text_header p{
      margin-bottom:4px;
      color: #05000F;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
  
  
  }
  .assessment_text_content
  {
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #9F99AB;
  }
  .instructions
  {
      margin-bottom: 16px;
  }
  .header_instructions
  {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
  }
  .sub-instructions
  {
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #888096;
  
  }
  .answered_div
  {
      display: flex;
      justify-content:flex-start;
  }
  .icons
  {
      width: 16px;
      height: 16px;
  }
  .green_icon
  {
      margin-right: 16px;
  }
  .button_div
  {
      margin-top: 16px;
  }
  .submit_button
  {
      width: 100%;
      /* height: 64px; */
      padding: 16.5px 0px 17.5px 0px;
      border-radius: 16px;
      background: #9566E8;
      color: #ffff;
      border: 1px #9566E8;
  
  }
  .assessment_name_div
  {
      margin-top: 24px;
      margin-bottom: 24px;
      font-family: "Poppins";
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
  
  }
  .animation_div
  {
      display: flex;
      justify-content: center;
  }
  .marks
  {
      align-items: center;
      /* margin-left: 32%; */
      width: 165px;
      height: 86px;
      padding: 16px 24px 16px 24px;
      border-radius: 16px;
      gap: 4px;
      background: #F2F6EF;
  }
  .marks_div
  {
      margin-bottom: 24px;
      font-family: "Poppins";
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: center;
  }
  
  .internal_marks_div {
      display: flex;
      justify-content: center;
  }
  .button_div
  {
      margin-top: 48px;
  }
  .next_button,.next_button:hover
  {
      border-radius: 16px;
      width: 335px;
      height:48px;
      gap: 16px;
      color:  white;
      border: 1px solid #9566E8;
      background-color:#9566E8;
  }
