.forgetPassMain {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.forgetPass-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  color: #05000f;
}

.forgetPass-label_text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #888096;
}

.forgetPassCard {
  width: 567px;
  height: 500px;
  padding: 50px 64px 64px 64px;
  border-radius: 16px;
  border: none;
}

.forgetPassMain input {
  border-radius: 16px;
}

.forgetPassInput {
  padding: 10px;
}

.logInLink {
  color: #9566e8;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;
}

.resetPassMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.resetPass-title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  color: #05000f;
}

.resetPass-label_text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #888096;
}

.resetPassCard {
  width: 567px;
  height: 600px;
  padding: 50px 64px 64px 64px;
  border-radius: 16px;
  border: none;
}

.resetPassInput {
  padding: 10px;
  border-radius: 16px;
  border-right: none;
}
.resetPassInput:focus {
  border: 1px solid #ced4da;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-right: none;
}
.successfullMSg {
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #0f0f0f;
}

.loginToMsg {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #666666;
}
.eye_icon_conf_pass {
  cursor: pointer;
  width: 24px;
  /* margin-left: 400px;
  margin-top: -40px;
  position: absolute; */
}
.showPassBtn {
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 16px;
}
.showPassBtn:focus {
  border: 1px solid #ced4da;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-left: none;
}
.eye_icon_reset_pass {
  cursor: pointer;
  width: 24px;
  /* margin-left: 400px;
  margin-top: -40px;
  position: absolute; */
}
@media (max-width: 575.98px) {
  .resetPass-title {
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
  }

  .resetPassCard {
    padding: 64px 30px;
    height: 608px;
  }

  .loginBtn {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
  }

  /* .resetPass-label_text {

     } */
}
