.EditStudentProfile input {
    border: 1px solid #E7E6EA;
    border-radius: 16px;
    color: #05000F;
    font-size: 14px;
}

.EditStudentProfile label {
    color: #9F99AB;
    margin-bottom: 8px;
    margin-top: 8px;
}

.bloodGroupSelect {
    border: 1px solid #E7E6EA !important;
    border-radius: 16px !important;
}

.profile_title {
    color: #211C37;
    font-weight: 400;
}

.EditStudentProfile input::placeholder {
    color: #05000F;
    font-size: 14px;
}
.update_btn{
    width: 100%;
    background-color: #9566E8;
    color: white !important;
    padding: 10px;
}