/* .schoolProfile .container
{
  font-family:'poppins';
  display: left;
} */
.profile_header {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

/* 
.img-div
{
  width: Hug (146px);
  height: Hug (141px);
  gap: 12px;

} */
.school_logo {
  width: 101px;
  height: 101px;
  border: 1px solid #E7E6EA;
  border-radius: 50px;
}

.school-name-div {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 500;
  /* line-height: 28px; */
}

hr {
  border: 1px solid #E7E6EA;
  border-top: 1px solid;
  height: 1px;
  width: 80%;
}

.Enrolled_div {
  padding-top: 10px;
}

.sub-header {
  width: 150px;
  height: 18px;
  color: #9F99AB;
  font-size: 12px;
}
.school_data {
  color: #05000F;
}

