.Syllabus_Images {
  width: 100%;
  max-width: 100%;
}
.blur_img {
  background-image: url("../Images/img-placeholder.png");
  background-repeat: no-repeat;
  height: 180px;
  width: 90%;
  background-size: 100% 100%;
  margin-bottom: 40px;
}
.syllabus_title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.chapter_number {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #888096;
  margin-bottom: 0px;
}

.chapter_title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  /* margin-top: 3px; */
}

.chapter_desc {
  margin-top: 10px;
}

.project_desc {
  margin-top: 12px;
  display: flex;
}

.routes-button-div {
  display: flex;
  flex-direction: row;

  padding: 8px 12px 8px 12px;
  float: left;
  margin-bottom: 10px;
}

.routes-button {
  /* margin: 10px; */
  margin-right: 10px;
  /* background: #9566E8; */
  /* color: white; */
  color: black;
  border-radius: 36px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  background: #f8f8f9;
  /* width: 83px; */
  /* height: 36px; */
  /* width: Hug (80px)
height: Fixed (36px) */
  padding: 8px 12px 8px 12px;
  border-radius: 36px;
  gap: 10px;
}

.routes-button:hover {
  background: #9566e8;
  color: white;
}

.active {
  background: #9566e8;
  color: white;
}
.container-fluid {
  font-family: "Poppins";
  margin: 60px;
}
.First-Header {
  /* width: 103px;
  height: 36px; */
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.Header-video-div {
  width: 100%;
  /* height:76px; */
  /* gap: 4px; */
  margin-bottom: 16px;
  /* display: flex; */
  /* flex-direction: column; */
}
.Topic-div {
  width: 100%;
  /* height: 48px; */
}
/* .Header-Video
{
  width: 100%;
  height: 48px;
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: 500;
  line-height: 48px; */
/* letter-spacing: 0em;
  text-align: left; } */

.Header-Video {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}

.Image-Div {
  width: 100%;
}
.Video-Image {
  border-radius: 16px;
  gap: 10px;
}

.Description-Div {
  margin-top: 24px;
  gap: 16px;
}
.Description-Header-Text {
  color: #2d2f31;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.Description-Text {
  margin-top: 16px;
}
.inner-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.Topics-Covered-Div {
  /* width: 188px;
  height: 29px; */
  margin-top: 5px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  /* margin-bottom: 24px; */
}
.Topics-Covered-List {
  /* width: 338px;
  height:376px; */
  margin-bottom: 8px;
}
.Content-1 {
  display: flex;
  padding: 16px 16px 16px 12px;
  border-radius: 24px;
  background: #f8f8f9;
  float: left;
  margin-bottom: 5px;
}
.Content-1 p {
  /* width: 270px;
  height:24px; */
  /* gap: 4px; */
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  /* text-align: right; */
}
.activeVideo {
  background-color: #ede5fb;
}
.project_div {
  /* background-color: rgba(248, 248, 249, 1); */
  padding: 16px;
  border-radius: 24px;
}
.project_main_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(5, 0, 15, 1);
}
.project_no {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(112, 102, 129, 1);
}
.project_aim_title,
.project_objective_title {
  color: rgba(5, 0, 15, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}
.project_aim_description,
.project_objective_description {
  color: rgba(136, 128, 150, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
}
.conclusion_title {
  color: rgba(5, 0, 15, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}
.conclusion_details {
  color: rgba(136, 128, 150, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
}
.proj_name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(5, 0, 15, 1);
}
.filterImg {
  filter: brightness(0) invert(1);
}
.selectedProjectName {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(5, 0, 15, 1);
  margin-bottom: 20px;
}
