.fc-col-header-cell-cushion {
  font-size: 20px;
  color: black;
  text-decoration: none;
}
.fc .fc-daygrid-day-number {
  color: black;
  text-decoration: none;
}
.attendance_status {
  position: absolute !important;
  width: 100%;
  border: 4px solid blue;
}

.fc .fc-button-primary,.fc .fc-button-primary:hover, .fc .fc-button-primary:active{
  background: #9566E8;
  border: none;
}

.month-summary{
  color: var(--text-40-dark-grey, #9F99AB);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.month-summary-details{
  display: flex;
  justify-content: space-between;

}

.fc-daygrid-day-top {
  justify-content: center;
  align-items: center;
}
.fc .fc-daygrid-day-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fc-daygrid-day-events {
  width: 100%;
  height: 100%;
}
.fc-h-event {
    background-color: white; 
    border: 1px solid white;
    display: block;
}