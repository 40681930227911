.vertical-nav .nav-link {
    color: var(--Text-50, #888096);
    font-weight: 400;
    line-height: normal;
    margin: 26px 0;
}

.design_component {
    align-items: center;
    border-radius: 16px;
}

.sidebody {
    margin-left: 14rem;
    margin-right: 2rem;
}

.sidebar .active {
    border-radius: 16px;
    background: var(--Primary-60, #9566E8);
    color: var(--Basics-White, #FFF) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    img{
        filter: brightness(0) invert(1);
    }
}

@media (min-width:992px) {
    .vertical-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 200px;
        height: 100%;
        background: #F8F8F9;
        overflow-y: auto;
        padding: 10px;
    }
}