@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.loginPage {
    width: 1100px;
    background: lightgray -495.872px 0px / 176.628% 100% no-repeat, #D9D9D9;
    margin: auto;
    padding: auto;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120vh;}

.loginPage label {
    color: var(--Text-50, #888096);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-top: 24px; */
    margin-bottom: 6px;
}

.loginPage input {
    border-radius: 16px;
    border: 1px solid var(--text-20-stroke, #E7E6EA);
}

.loginBtn {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 16px;
    background: var(--Primary-60, #9566E8);
    margin-top: 24px;
}

.loginBtn:hover {
    color: #FFF;
}

.invalidcredential {
    display: none;
}

.loginTitle {
    color: var(--text-100-dark, #05000F);
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
}
.phnElearnLogo{
    width: 45%;
}
.terms {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 22px;
}

.error-msg {
    color: red;
    font-size: 14px;
}

.forgotPassBtn,.forgotPassBtn:hover {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #9566E8; 
    text-decoration: none;
}
.login_show_pass_icon {
    cursor: pointer;
    width: 24px;
    margin-left: 19rem;
    margin-top: -40px;
    position: absolute;
  }

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .loginPage {
        width: 40rem;
        padding: 20px 0;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .loginPage {
        width: 1000px;
        padding: 20px 0;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px){
    .loginPage {
        height: 100vh;
    }
}
.eye_icon
{ 
    position: absolute;
    right:26%;
    bottom:40.5%; 
    width: 24px;
    transform: translateY(-50%);
    cursor: pointer;

}