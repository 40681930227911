.studentListTitle {
  color: #211c37;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exportBtn {
  border-radius: 12px;
  border: 1px solid var(--text-20-stroke, #e7e6ea);
  color: var(--Text-60, #706681);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-toggle {
  /* color: var(--Text-60, #706681); */
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dropdown {
  border-radius: 12px;
  border: 1px solid var(--text-20-stroke, #e7e6ea);
}

.custom-dropdown-toggle,
.custom-dropdown-item {
  color: #000; /* Set text color to black */
}

.custom-dropdown-menu {
  background-color: #fff; /* Set background color to white */
}

.dropdown-icon {
  width: 24px;
  height: 24px;
  margin-left: 8px; /* Add some spacing between text and icon */
}

.addStudentBtn,
.addStudentBtn:hover {
  border-radius: 12px;
  border: 1px solid var(--Primary-60, #9566e8);
  color: var(--Primary-60, #9566e8);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.plusIcon {
  background-color: #cfbaf4;
  padding: 10px;
  border-radius: 30px;
}

.grades .nav-item {
  border-radius: 36px;
  background: var(--text-10-elements, #f8f8f9);
  padding: 0px 4px;
  color: var(--text-100-dark, #05000f);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::file-selector-button {
  display: none;
}

.grades .active {
  border-radius: 36px;
  background: var(--Primary-60, #9566e8);
  color: #fff !important;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

table tbody {
  border-top: 2px solid #f2f2f2;
  color: #676767;
  font-family: Poppins;
  font-size: 13.782px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

tr {
  height: 4rem;
}

.stud_id {
  color: var(--text-100-dark, #05000f);
  /* text-align: center; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
}

table th {
  color: #84868a;
  font-size: 11.765px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.stud_name {
  color: var(--text-100-dark, #05000f);
  /* text-align: center; */
  font-size: 12.863px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.attendancebox {
  width: 36px;
  height: 36px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  margin: 2px;
}

.present {
  background: var(--Green-60, #427a0a);
  color: white;
}

.absent {
  background: var(--Red-70, #da4505);
  color: white;
}

.bulkuploaddropdown {
  border: 1px solid;
  border-radius: 4px;
}

.bulkuploadfile {
  width: 40%;
  border-radius: 6px;
  border: 1px solid var(--text-20-stroke, #e7e6ea);
  padding: 10px;
}
.bulk_upload_btn {
  padding: 10px;
}
.noDataContainer {
  display: flex;
  position: absolute;
  left: 50%;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.noData {
  font-size: 1.5rem;
  color: black;
}

.starAssessment {
  font-family: "poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #9f99ab;
}

.watchAllVideos {
  font-family: "poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #9f99ab;
}

.assessmentPending,
.assessmentStart {
  background: #f8f8f9;
  padding: 16px;
  border-radius: 24px;
}

.assessmentStart .starAssessment {
  color: #05000f;
}

.assessmentCompleted {
  background: linear-gradient(272.74deg, #8752e4 0%, #a57deb 100%);
  padding: 16px;
  border-radius: 24px;
}

.your_score_title {
  font-family: "poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.scoreNo {
  font-family: "poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.topicTitle {
  font-family: "poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #05000f;
}

.videoTime {
  font-family: "poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #9f99ab;
}

.prevPageTitle,
.prevPageTitle:hover,
.currentPageTitle {
  color: #9f99ab;
  font-family: "poppins";
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
}

.Header-video-div .videoTitle {
  font-family: "poppins";
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #05000f;
}

.Header-video-div .videoSubTitle,
.Header-video-div .Description-Div {
  font-family: "poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #888096;
}

.desc {
  font-family: "poppins";
  /* font-size: 12px; */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #888096;
}

.continueChapterTitle {
  font-family: "poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #131313;
}

.continueChapterName {
  font-family: "poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #05000f;
}

.viewAllChapter,
.viewAllChapter:hover {
  font-family: "poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #9566e8;
  text-decoration: none;
}
.chapterVideos .active {
  background-color: #ede5fb;
}
@media (max-width: 1309px) {
  .bulk-upload-mainDiv {
    display: flex;
  }
}
@media (min-width: 1400px) {
  .second-column {
    width: 30%;
  }
}

.pagination {
  /* display: flex;
    justify-content: space-between;
    */
  border-top: 1px solid #e7e6ea;
  padding-top: 5px;
}

.result_msg {
  /* margin-left: 20px ;
       */
  padding-left: 20px;
  /* padding: 5px; */
}

.result_msg_text {
  font-family: "poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #5c677d;
  margin-bottom: 0;
  margin-top: 10px;
}

.paginate_msg_text {
  font-family: "poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #5c677d;
  margin-bottom: 0;
  margin-right: 14px;
  margin-left: 16px;

  /* width: 160%;
    padding: 6px; */
}

.pagination_prev {
  width: auto;
  height: 12px;
}

.pagination_btn {
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 2px;
  /* padding-right: 10px; */
  padding-bottom: 10px;
  margin-right: 10px;
}

.bton {
  /* width:20px; */
  /* height:20px; */
  width: 34px;
  height: 34px;
  padding: 4px;
  border-radius: 6px;
  border: 1px;
  margin-right: 6px;
  /* gap: 5px; */
}

.arrow_btn {
  margin-bottom: 12px;
  width: 24px;
  height: 24px;
}
.student_list_structure_grades {
}
.student_list_structure_grades ul {
  padding-left: 0;
}

.student_list_structure_grades ul {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
}

.student_list_structure_grades ul li {
  list-style-type: none;
}
.student_list_structure_grades .nav-link {
  color: #000;
}
.button_module {
  padding-left: 53%;
}
