.day {
  color: #211c37;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
  cursor: default;
}

.date {
  color: var(--text-40-dark-grey, #9f99ab);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.profileInitial {
  color: #0f0f0f;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #e7e6ea;
  width: 40px;
  padding: 5px;
  border-radius: 50px;
}

.markAsCompletedBtn {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #9566e8;
  border: 1px solid #9566e8;
  border-radius: 16px;
}
.completedText {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #706681;
}
.announcedData {
  margin-top: 25px;
  border-radius: 16px;
  /* opacity: 0.4; */
  /* background: var(--text-20-stroke, #E7E6EA); */
  background-image: url("../Images//Dashboard//announcementBg.webp");
  background-position: center;
  background-size: 100% 100%;
  padding: 20px 20px 0px 20px;
}

.announcedTitle {
  color: #211c37;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.announcedDetail {
  color: var(--Text-60, #706681);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.nameInitial {
  color: var(--Text-60, #706681);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 10px 18px;
  border-radius: 50px;
  background-color: white;
  text-align: center;
}

.postedByTitle {
  color: var(--Text-60, #706681);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.postedByName {
  color: var(--text-100-dark, #05000f);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.postedDate {
  color: var(--text-100-dark, #05000f);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 57px;
  background: var(--Basics-White, #fff);
  padding: 10px;
}

.postedTime {
  color: var(--text-100-dark, #05000f);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 57px;
  background: var(--Basics-White, #fff);
  padding: 10px;
}

.carousel-indicators {
  top: 16.5rem;
}

.carousel-dark .carousel-indicators .active {
  background-color: #9566e8;
  width: 30px;
  padding: 1px;
}

.carousel-indicators [data-bs-target] {
  width: 3px;
  background-color: #a37beb29;
  padding: 1px;
}

.more-container {
  position: relative;
}

.more-text {
  cursor: pointer;
  /* Change cursor to pointer to indicate interactivity */
}

.hide {
  display: none;
  position: absolute;
  top: calc(100% + 0px);
  /* Adjust as needed */
  left: 0;
  width: 264px;
  padding: 12px;
  border-radius: 12px;
  background-color: #281a42;
  color: whitesmoke;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  z-index: 1;
  /* Ensure it appears above other content */
}

.more-container:hover .hide {
  display: block;
}

.hide:hover {
  display: block;
}
.simple-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
