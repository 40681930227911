.avgCourseCompletion {
  border-radius: 16px;
  text-align: center;
  background: var(--Green-10, #fffbe3) !important;
  padding: 16px;
  border: none;
}
.dropdown-item {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-toggle {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 1.255em;
  vertical-align: 0.275em;
  content: "";
  border-top: 0.43em solid;
  border-right: 0.43em solid transparent;
  border-bottom: 0;
  border-left: 0.43em solid transparent;
}
.dropdown-menu {
  border: 0px, 0px, 1px, 0px;
  box-shadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px";
}
.detail-section {
  margin-top: 24px;
  margin-bottom: 64px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.adminDetailHeader-text {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #211c37;
}

.adminDetail-inputs-main {
  /* width: 50%; */
  /* width: 100%; */
  max-width: 524px;
  width: 100%;
  /* border-radius: 16px; */
  /* height: 48px; */
}

.adminInputField {
  width: 524px;
  height: 48px;
  max-width: 100%;
  padding: 14px 16px 14px 16px;
  border-radius: 16px;
  border: 1px solid #e7e6ea;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #9F99AB; */

  /* justify: space-between */
}

.adminDetail-inputs {
  margin-bottom: 16px;
}

.adminSelectedSchool-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #9f99ab;
}

.adminSelectedSchool {
  /* padding: 16px; */
  /* gap: 16px */
  border-radius: 24px;
  margin-bottom: 10px;
  background: #f8f8f9;
  border: none;
}

.adminSelectedSchool_card-body {
  display: flex;
  justify-content: space-between;
}

.selectedSchoolName {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 24px; */
  letter-spacing: 0em;
  text-align: left;
  color: #05000f;
  padding: 6px 10px;
  margin: 0px;
}

.adminInputDropdown-card {
  border-radius: 16px;
  margin-bottom: 16px;
  /* height: 48px; */
  max-height: 100%;
  border-width: 0px, 1px, 1px, 1px;

  border-style: solid;

  border-color: #e7e6ea;
}

.adminDropdown-search {
  /* width: Fill (496px)
  
height: Fixed (36px) */
  margin-top: 10px;
  width: 496px;
  padding: 8px 16px 8px 16px;
  border-radius: 12px;
  border: 1px solid #e7e6ea;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #B7B3C0; */
}

.adminDetail-dropdown-content {
  width: 100%;
}

.adminDropdownField {
  /* width: 524px; */

  max-width: 100%;
  /* padding: 14px 16px 14px 16px; */
  /* border-radius: 16px; */
  border: 1px solid #e7e6ea;
  /* margin-bottom: 16px; */
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #9F99AB; */
}

.adminDropdown_card-body {
  padding: 14px 16px 14px 16px;
}

.adminSchoolList {
  margin-top: 10px;

  padding: 14px 16px;
}

.adminlist-group-item {
  /* margin-bottom: 10px; */
  border-bottom: 1px solid #e7e6ea;
  margin-top: 2px;
  border: none;
  border-bottom: 1px solid #e7e6ea;
}

.schoolList-schoolname {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  padding: 2px 10px 0px 10px;
  margin-bottom: 0px;
}

.schoolList-districtname {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  padding: 2px 6px 2px 6px;
  margin-bottom: 0px;
  color: #888096;

  /* text-align: left; */
}

.adminSchoolListingDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 2px; */
}


.next_prev_button_div {
  display: flex;
  margin-top: 10px;
}

.cancel-btn-admin,.cancel-btn-admin:hover{
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 20vw;
  /* max-width: 335px; */
  /* width: 80%; */
  height: 48px;
  /* gap: 16px; */
  border-radius: 32px;
  color: #9566e8;
  border: 1px solid #9566e8;
  background-color: white;
}

.add-btn-admin,.add-btn-admin:hover{
  border-radius: 32px;
  /* width: 335px; */
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 20vw;
  /* max-width: 335px; */
  height: 48px;
  /* gap: 16px; */
  color: white;
  border: 1px solid #9566e8;
  background-color: #9566e8;
}
.next_button:hover {
  color: #9566e8;
  background-color: white;
  /* background-color:#9566E8 ; */
  /* color: black; */
  border: 1px solid #9566e8;
}

.icon {
  /* float: right; */
  float: right;
  font-size: 18px;
  width: 1.2em;
  color: #05000f;

  /* color: #07dab6; */
  margin: 0px;
  transition: all 0.3s ease;
}

.iconup {
  transform: rotate(-180deg);
}

.adminDetail_school-logo {
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 10px;
  /* font-size: 2px; */
}

.admin_DropdownList_logo {
  border-radius: 6px;
}

.error-text {
  font-size: 12px;
  color: red;
  padding: 2px 10px 2px 20px;
  margin: 0;
}

.more-schools {
  position: relative;
}

.more-school-text {
  cursor: pointer;
}

.hideMoreSchools {
  display: none;
  position: absolute;
  top: calc(100% + 0px);
  left: 30%;
  width: 264px;
  padding: 12px;
  border-radius: 12px;
  background-color: #281a42;
  color: whitesmoke;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  z-index: 1;
}

.more-schools:hover .hideMoreSchools {
  display: block;
}

.hideMoreSchools:hover {
  display: block;
}
