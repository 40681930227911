.profile_title {
    color: #000 !important;
    font-family: Poppins;
    font-size: 21.599px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
}

.student_name {
    color: #000;
    font-family: Poppins;
    font-size: 18.526px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 18px;
}

.grade {
    color: var(--Text-50, #888096);
    font-family: Poppins;
    font-size: 13.728px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.data_title {
    color: var(--text-40-dark-grey, #9F99AB);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.data_subititle {
    color: var(--text-100-dark, #05000F);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.stud_addres_title {
    color: var(--text-40-dark-grey, #9F99AB);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.stud_address_subtitle {
    color: var(--text-100-dark, #05000F);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}