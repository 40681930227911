.input-error {
    padding-bottom: 20px;
}

/* .Gap
{
  padding:36px;
} */
.contacts-gap {
    margin-bottom: 16px;
}

.POC-inner-div {
    border-radius: 16px;
    border: 1px solid var(--text-20-stroke, #E7E6EA);
    background: var(--text-10-elements, #F8F8F9);
    padding: 16px 35px;
}

.boundary-1 {
    background: #E7E6EA;
    width: 100%;
    height: 1px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.boundary-2 {
    background: #E7E6EA;
    width: 100%;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.headers {
    color: #211C37;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.small-headers {
    color: var(--text-100-dark, #05000F);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Enrollment-block {
    padding-bottom: 24px;
}

.custom-dropdown {
    display: inline-block;
    position: relative;
    width: 100%;
}

.custom-select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.cancel-btn,.cancel-btn:hover {
    border: 1px solid var(--text-30-grey, #B7B3C0);
    border-radius: 55px;
    color: var(--text-30-grey, #B7B3C0);
    font-family: poppins;
    height: 10%;
    width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;

}

.add-btn,
.add-btn:hover {
    background: var(--Primary-60, #9566E8);
    color: white !important;
    font-family: poppins;
    height: 10%;
    border-radius: 32px;
    width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
}

.AddSchool input {
    border-radius: 16px;
    border: 1px solid var(--text-20-stroke, #E7E6EA);
    color: var(--text-40-dark-grey, #9F99AB);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
}

.custom-select {
    color: var(--text-40-dark-grey, #9F99AB);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
}

.gradeCircle {
    border-radius: 38px;
    background: var(--text-10-elements, #F8F8F9);
    padding: 8px 0px;
    width: 3rem;
}
/* input[type=checkbox] + label {
    color: #ccc;
    font-style: italic;
  }  */
input[type=checkbox]:checked + .gradeCircle{
    border-radius: 46px;
    background: var(--Primary-60, #9566E8);
    color: #FFF;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  } 
.grade_selection .active {
    border-radius: 46px;
    background: var(--Primary-60, #9566E8);
    color: #FFF;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* .parent {
    width: 450px;
    margin: auto;
    padding: 2rem;
    background: #ffffff;
    border-radius: 25px;
    box-shadow: 7px 20px 20px rgb(210, 227, 244);
} */

.file-upload {
    /* text-align: center; */
    border: 1px dashed #9566E8;
    border-radius: 16px;
    padding: 10px;
    position: relative;
    cursor: pointer;
}

.file-upload input {
    display: block;
    position: absolute;
    cursor: pointer;
}

.editeSchool input,.custom-select{
    color: #05000F !important;
    font-size: 14px !important;
}
.delete_btn{
    background-color: white;
    border: 1px solid #DA4505;
    border-radius: 10px;
}
.deleteSchoolModal{
    padding: 20px;
}
.deleteSchoolModal .modal-header{
    border-bottom: none;
}
.gradeCheckboxes{
    position: absolute;
    margin-left: -36px;
    margin-top: 2px;
    width: 20px; height: 20px;
    /* z-index: -1; */
}
