.total_students {
    border-radius: 16px;
    background: var(--Red-10, #FFF4F0);
    border: none;
    text-align: center;
    padding: 16px;
}

.totalNo {
    color: #131313;
    text-align: right;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    line-height: normal;
}

.totalTitle {
    color: #1F1D39;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.reportTitle {
    color: #131313;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 17px;
}

.avg_completion {
    border-radius: 16px;
    text-align: center;
    background: var(--Green-10, #F2F6EF);
    padding: 16px;
    border: none;
}

.addStudent input {
    border: 1px solid #E7E6EA;
    border-radius: 16px;
    padding: 13px;
    margin-top: 12px;
}

.modal-content {
    border-radius: 24px;
}

.selectSchool select {
    margin-top: 30px;
    border: none;
    color: #211C37;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../Images//Dashboard//dropdown_arrow.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 6px;
    padding-right: 2rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
   padding-left: 1rem;
}

/* #root>div>div.container>div>select>option:nth-child(1),
#root>div>div.container>div>select>option:nth-child(2),
#root>div>div.container>div>select>option:nth-child(3) {
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
} */

.poc_card {
    background-color: #F8F8F9;
    border: none;
    padding: 16px 30px;
    border-radius: 16px;
}

.poc_name {
    color: #05000F;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.phone_no,
.address,
.poc_email {
    color: #706681;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

/* .Trainer-main-section {
    padding-bottom: 60px;
    margin: 10px;
} */


.trainer-title-text {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9F99AB;
    margin-bottom: 0;
}


.school-allocated-card-body-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.trainer-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

/* .trainer-body-text {
    padding: 5px;

    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

} */

.schools-allocated-card {
    /* background: #F8F8F9; */
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19);
    padding: 15px;
    border-radius: 30px;
    margin-top: 10px;
    margin-bottom: 12px;
    border: none;
    height: 80%;
    transition: transform 0.3s ease;
}

.schools-allocated-card-active{
    background: #f6f2f6;
}

.schools-allocated-card .eye-icon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #333;
  }
  
  .schools-allocated-card:hover .eye-icon {
    display: block;
  }

.schools-allocated-card:hover {
    transform: scale(1.05);
  }

.trainer-schools-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

/* .schools-allocated {
    margin: 30px 0px;
} */

.separation-line {
    border: 1px solid #E7E6EA;
}

/* .school-allocated-row-div {
    display: flex;
    margin-top: 2px;
} */
.schoolDropdown{
    margin-left: 6rem;
}
.studentList .active{
    color: white !important;
}


@media (min-width: 991px) and (max-width: 1300px) { 
    .schoolDropdown{
        margin-left: 10rem;
    }
 }