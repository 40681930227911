.videoTitle {
  color: #211c37;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 12px;
}

.videoDescription {
  color: #2d2f31;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  margin-top: 8px;
}

.videoSection .card {
  border-radius: 16px;
  border: none;
  background: var(--text-20-stroke, #e7e6ea);
  /* padding: 81px; */
}

.recentlyVideo .card {
  border-radius: 16px;
  border: none;
  background: var(--text-20-stroke, #e7e6ea);
  /* padding: 40px; */
}

.videoList {
  border-radius: 16px;
  border: 1px solid var(--text-20-stroke, #e7e6ea);
  /* padding: 20px;
     */
  padding: 20px 10px 2px 10px;
}

.recentlyVideoTitle {
  color: #131313;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.view_all_btn {
  color: var(--Primary-60, #9566e8);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btn-close {
  border: 1px solid;
  border-radius: 30px;
}

.modal-title {
  color: #211c37;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gradeCircle {
  border-radius: 38px;
  background: var(--text-10-elements, #f8f8f9);
  padding: 12px;
}

.grade_selection .active {
  border-radius: 46px;
  background: var(--Primary-60, #9566e8);
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.youtube_section input {
  border-radius: 16px;
  border: 1px solid var(--text-20-stroke, #e7e6ea);
  padding: 10px 10px;
  margin-top: 0px;
  width: 17rem;
  margin-bottom: 10px;
}

.youtube_title {
  color: var(--text-100-dark, #05000f);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 20px;
}

.submit_btn,
.submit_btn:hover {
  border-radius: 32px !important;
  background: var(--Primary-60, #9566e8);
  color: var(--Basics-White, #fff) !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 60% !important;
  border: none;
  padding: 10px !important;
}
.attendance_submit_btn,
.attendance_submit_btn:hover {
  position: absolute;
  width: 200px !important;
}

.playBtn {
  position: absolute;
  top: 40%;
  left: 40%;
  filter: brightness(0) invert(1);
}

.video_title {
  color: var(--text-100-dark, #05000f);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.error_msg {
  color: red;
}

.modal-dialog {
  max-width: 37rem;
  margin: 1.75rem auto;
}

.chart_title {
  color: #131313;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.videoCard img {
  border-radius: 20px;
  /* height: 16rem;
    width: 16rem; */
}
.videoCard {
  background: none !important;
}
.presentCount {
  font-size: 22px;
  color: rgb(61, 145, 68);
}
.absentCount {
  font-size: 22px;
  color: rgb(233, 107, 107);
}
